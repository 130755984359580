<template>
  <div class="about" id="about">
    <div class="banner">
      <div class="banner-box">
        <div class="banner-title">关于前图</div>
        <div class="title-eng">About Qiantu</div>
        <div class="banner-msg">私人定智 美宅之选</div>
      </div>
    </div>
    <div class="main">
      <div class="main-box">
        <div class="text-box">
          <div class="h">前图品牌简介</div>
          <div class="p">
            宁波前图物联科技有限公司，致力于为用户提供“极智”的智慧生活，并为用户提供一站式物联网解决方案，涵盖设备智能化、云服务、APP软件开发及运营维护等方面，搭载场景化SaaS服务，实现C端精细化运营。通过改变传统的智能硬件式消费，对产品进行围绕用户的体验设计。前图团队深耕行业十余年，充分了解用户需要，产品从表象化走向灵魂化，为每个用户提供“私人定制”的美好生活；同时，前图在技术端实现了“无编程｜零门槛｜半小时”，为智能走向千家万户打下坚实的基础。
          </div>
          <div class="p">
            前图物联以成熟的海量智能解决方案，助力智能场景的快速落地，能满足多种智能化升级需求，创造全新的智能商业价值！
          </div>
        </div>
        <div class="num-box">
          <div class="left">
            <div class="background">
              <!-- <img src="../assets/images/AboutUs_linglu@2x.png" alt="" /> -->
              QIANTU
            </div>
            <div class="msg">
              <p>
                前图智能作为一家创新型企业，将技术极简化和易交互化，让渠道
                <br />专注于为用户提供高价值的全屋智能生活
              </p>
            </div>
            <div class="num-msg">
              <div class="num-item">
                <div class="nums">无编程</div>
                <span>高效编辑，降低成本</span>
              </div>
              <div class="num-item">
                <div class="nums">零门槛</div>
                <span>全系统采用轻架构，稳定且易上手</span>
              </div>
              <div class="num-item">
                <div class="nums">半小时</div>
                <span>巧创独有的DIY方式，半小时完成全屋智能的调试
                </span>
              </div>
            </div>
          </div>
          <div class="right">
          </div>
        </div>
        <div class="big-box">
          <div class="top" style="opacity: 0;">
            <div class="img">
            </div>
          </div>
        </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import imgsUrl from '../utils/imgAddress';
export default {
  name: "Carousel",
  data() {
    return {
      domObj: null,
      bannerUrl: imgsUrl.bannerMd.abdoutBanner,
      aboutVideo: imgsUrl.video.aboutVideo,
      tsUrl: imgsUrl.bannerMd.solutionBanner,
      newsList: this.$store.state.newsList

    };
  },
  watch:{
    $route(){
      this.scrollMd()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.scrollMd()
    })

  },
  methods: {
    scrollMd(){
        let toElement = document.getElementById('about');
        toElement.scrollIntoView();
    },
    handleRouter(id){
      this.$router.push('/newscentercontent?id=' +id)

    }
  }
};
</script>
<style scoped lang="scss">
.about {
  .banner {

    background-size: cover;
    background-position: top;
    background-position-x: center;
    background-position-y: top;
    width: 100%;
    height: 900px;

    position: relative;
    background-image: url('../assets/images/abdout_banner.png');

    .banner-box {
      position: absolute;
      top: 30%;
      left: 10%;
      color: #3E86F7;

      .banner-title {
        width: 220px;
        font-size: 48px;
        letter-spacing: 5px;
        padding-bottom: 9px;
        border-bottom: 3px solid #d8d8d8;
        margin-bottom: 29px;
      }

      .title-eng {
        width: 343px;
        font-size: 48px;
        letter-spacing: 2px;
        padding-bottom: 9px;
        border-bottom: 3px solid #d8d8d8;
        margin-bottom: 29px;
        white-space: nowrap;
      }

      .banner-msg {
        font-size: 22px;
        letter-spacing: 2px;
      }
    }
  }

  .main {
    margin-top: 106px;
    display: flex;
    justify-content: center;
    letter-spacing: 2px;
    line-height: 22px;

    .main-box {
      width: 1400px;

      .text-box {
        height: 250px;
        margin: 0 auto 100px auto;
        color: rgba(128, 128, 128, 1);
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        font-size: 18px;
        line-height: 35px;

        .h {
          font-size: 26px;
          color: rgb(255, 255, 255, 1);
          margin-bottom: 24px;
        }

        .p {
          text-align: justify;
        }
      }

      .num-box {
        margin: auto;
        display: flex;
        justify-content: space-between;

        .left {
          width: 55%;
          color: #999999;
          font-size: 14px;
          line-height: 26px;
          display: flex;
          flex-direction: column;

          .background {
            width: 220px;
            font-size: 48px;
            letter-spacing: 5px;
            padding-bottom: 9px;
            margin-bottom: 29px;
            color: white;
            // img {
            //   height: 120px;
            // }
          }

          .msg {
            height: 140px;
            justify-content: center;
          }

          .num-msg {
            height: 200px;
            display: flex;
            justify-content: space-between;

            .num-item {
              height: 100%;

              .nums {
                display: flex;
                align-items: center;
                height: 40%;
                font-size: 32px;
                color: #fff;
              }
            }
          }
        }

        .right {
          width: 38%;
          display: flex;

          .background {
            margin-top: 140px;
            width: 100%;
            background: url("../assets/images/AboutUs_picture_1@2x.png");
            background-size: 100% 100%;
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-between;

            .box {
              color: #fff;
              height: 30%;
              margin: 0 10%;

              .title {
                height: 35%;
                font-size: 20px;
              }

              span {
                font-size: 14px;
              }
            }
          }
        }
      }

      .big-box {
        height: 600px;
        margin-bottom: 10%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        background: url("../assets/images/AboutUs_picture_2.png");
        background-size: 100% 100%;
        color: rgb(255, 255, 255);

        .top {
          height: 65%;
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;

          .img {
            width: 38%;
            height: 100%;
            background: url("../assets/images/AboutUs_picture_3@2x.png");
            background-size: 100% 100%;
            display: flex;

            .box {
              color: #fff;
              height: 30%;
              margin: auto 0 0 10%;

              .title {
                height: 35%;
                font-size: 20px;
              }

              span {
                font-size: 14px;
              }
            }
          }
        }

        .buttom {
          margin: 0 4%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 50%;

          .t-title {
            height: 35%;
            color: rgb(255, 255, 255);
            font-size: 26px;
          }

          .t-buttom {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 70%;

            .t-box1 {
              width: 27%;
              display: flex;
              flex-direction: column;
              font-size: 12px;
            }

            .t-box2 {
              width: 34%;
              display: flex;
              flex-direction: column;
              font-size: 12px;
            }

            .t1 {
              height: 18%;
              margin-bottom: 20px;
              font-size: 20px;
            }
          }
        }
      }

      .movie-box {
        // height: 750px;
        display: flex;
        flex-direction: column;
        margin-bottom: 150px;

        .movie {
          height: 650px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          color: rgb(255, 255, 255);
          font-size: 40px;
          background-color: rgb(0, 0, 0, 1);

          .about-video {
            width: 100%;
          }

          .t1 {
            margin: 20px;
            height: 60px;
          }

          .t2 {
            font-size: 28px;
          }
        }
      }

      .frend-box {
        // height: 750px;
        display: flex;
        flex-direction: column;
        margin-bottom: 150px;

        .frend {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          color: rgb(255, 255, 255);
          font-size: 40px;
        }

        .t3 {
          margin: 40px 0;
          font-size: 32px;
        }

        .t4 {
          font-size: 18px;
          color: rgba(128, 128, 128, 1);
          line-height: 30px;
          letter-spacing: 2px;
        }

        .news-box {
          img{
            width: 100%;
            height: 280px;
          }
          .news-content {
            margin-top: -10px;
            padding: 23px 18px;
            color: aliceblue;
            background: #262626;
            color: #808080;
            height: 240px;
            line-height: 25px;

            h2 {
              color: #fff;
              font-size: 20px;
              font-weight: normal;
              margin: 0;
            padding: 0;
            }
          }

        }

        .scrollBox {
          height: 490px;
          margin-bottom: 100px;
          /* 水平垂直居中 */
          display: flex;
          justify-content: center;
          align-items: center;
          color: #999999;
          white-space: nowrap;
          margin-top: 80px;
        }

        .img-box {
          width: 290px; //最右侧图大小不合适调这个
          height: 100%;
          overflow: hidden;
          transition: 0.5s;
          /* 动画过渡 */
          color: #fff;
          font-size: 22px;
          letter-spacing: 3px;
          position: relative; //相对定位
        }

        .img-box img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          /* object-position属性一般与object-fit一起使用，用来设置元素的位置 */
          // object-position: 50% 20%;
          //                     /* 设置元素的倒影效果，below是倒影在元素下方，15px是元素和倒影的距离，后面的属性是设置倒影渐变 */
          // -webkit-box-reflect: below 15px -webkit-linear-gradient (transparent
          //       60%, rgba(255, 255, 255, 0.3));
        }

        /* 默认第n张展开 */
        .img-box:nth-child(11) {
          width: 290px;
        }

        /* 鼠标移入，图片展开 */
        .img-box:hover {
          width: 800px; //反正调出来800比较合适...
        }

        .img-box:hover~.img-box:nth-child(11) {
          width: 290px; //防止最后一张被推走
        }

        .text {
          position: absolute; //绝对定位，字显示在上
          // text-align-last: center; //文本居中
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-content: center;

          .text-title,
          p {
            text-align: center;
          }

          p {
            font-weight: bold;
          }
        }

        .text p {
          letter-spacing: 1px;
          font-size: 16px;
          color: rgba(255, 255, 255, 0);
          transition: all 0.3s; //淡出
        }

        .text:hover p {
          color: rgba(255, 255, 255, 0.7);
          transition: all 0.5s; //淡入
        }
      }
    }
  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  // line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}
</style>
